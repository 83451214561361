import React from 'react';

const Context = React.createContext({
    login: false,
    setLogin: () => { },
    cart: [],
    setCart: () => { },
    configuracoes: { },
    setConfiguracoes: () => { },
    categorias: { },
    setCategorias: () => { },
    modal: false,
    setModal: () => { }
});

export default Context;