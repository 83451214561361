import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import ScrollToTop from "./Components/ScrollToTop";

import { Suspense, lazy, useState, useEffect } from 'react';

import Context from './Context';
import axios from "axios";

import { Helmet, HelmetProvider } from "react-helmet-async";
import { numbersOnly } from "./Utils";
import Whatsapp from "./Assets/images/icons/whatsapp.svg";

const Header = lazy(() => import('./Components/Header'));
const Footer = lazy(() => import('./Components/Footer'));

const Home = lazy(() => import('./Pages/Home'));
const Empresa = lazy(() => import('./Pages/Empresa'));
const Produtos = lazy(() => import('./Pages/Produtos'));
const Produto = lazy(() => import('./Pages/Produto'));
const Login = lazy(() => import('./Pages/Login'));
const Contato = lazy(() => import('./Pages/Contato'));
const Carrinho = lazy(() => import('./Pages/Carrinho'));
const Finalizar = lazy(() => import('./Pages/Finalizar'));
const Privacidade = lazy(() => import('./Pages/Privacidade'));


function App() {
  localStorage.setItem("apiUrl", "https://sistema.aluminiocajamag.com.br/");
  const [categorias, setCategorias] = useState([]);
  const [configuracoes, setConfiguracoes] = useState({});
  const [modal, setModal] = useState(false);
  const [login, setLogin] = useState(false);
  const [cart, setCart] = useState([]);
  const value = { categorias, setCategorias, configuracoes, setConfiguracoes, modal, setModal, cart, setCart, login, setLogin };

  useEffect(() => {
    axios.get(`${localStorage.getItem("apiUrl")}/api/configuracoes`).then(response => {
      if (response.data.status === 200) {
        setConfiguracoes(response.data.success.configuracoes);
        setCategorias(response.data.success.categorias);
      } else {
        setModal({
          titulo: "Ocorreu um erro!",
          texto: "Por favor, recarregue a página!",
          botao: "Voltar ao site"
        })
      }
    });
  }, []);

  useEffect(() => {
    let lgn = localStorage.getItem('lgn');
    let carrinho = localStorage.getItem('carrinho');

    if (lgn) {
      lgn = JSON.parse(lgn);
      axios.get(`${localStorage.getItem("apiUrl")}/api/check?id=${lgn.id}&codigo=${lgn.sess}`).then(response => {
        if (response.data.status !== 200) {
          setLogin(false);
          localStorage.removeItem("lgn");
          localStorage.removeItem("carrinho");
        } else {
          if (carrinho) {
            setCart(JSON.parse(carrinho));
          }
          setLogin(lgn);
        }
      });
    }
  }, []);

  return (
    <HelmetProvider>
      <Context.Provider value={value}>
        <div id="modal" className={modal ? "active" : ""}>
          {modal && <div className="content">
            <h3>{modal.titulo}</h3>
            <p>{modal.texto}</p>
            <button type="button" onClick={() => { if (modal.onExit) { modal.onExit() } setModal(false) }}>{modal.botao}</button>
          </div>}
        </div>
        <Helmet>
          <title>Alumínios Cajamag</title>
        </Helmet>
        <Router>
          <ScrollToTop />
          <div className="App">
            <Suspense fallback={<div></div>}>
              <Header />
              <Switch>
                <Route path="/" exact>
                  <Home />
                </Route>
                <Route path="/empresa" exact>
                  <Empresa />
                </Route>
                <Route path="/produtos" exact>
                  <Produtos />
                </Route>
                <Route path="/produtos/:cat/:slug" exact>
                  <Produtos />
                </Route>
                <Route path="/pesquisa/:pesquisa" exact>
                  <Produtos />
                </Route>
                <Route path="/produto/:prod/:slug" exact>
                  <Produto />
                </Route>
                <Route path="/carrinho" exact>
                  <Carrinho />
                </Route>
                <Route path="/finalizar" exact>
                  <Finalizar />
                </Route>
                <Route path="/login" exact>
                  <Login />
                </Route>
                <Route path="/privacidade" exact>
                  <Privacidade />
                </Route>
                <Route path="/contato" exact>
                  <Contato />
                </Route>
                <Route path="/recuperarsenha/:usuario/:codigo" exact>
                  <Login />
                </Route>
              </Switch>
              <Footer />
            </Suspense>
          </div>
        </Router>
        {configuracoes.whatsapp && <a href={`https://wa.me/55${numbersOnly(configuracoes.whatsapp)}`} target="_blank" rel="noreferrer noopener" className="floating-whatsapp" title="Fale conosco">
          <img src={Whatsapp} width="48px" height="48px" alt="whatsapp" />
        </a>}
      </Context.Provider>
    </HelmetProvider>
  );
}

export default App;
